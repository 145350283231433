<template>
	<div id="Mall">
		<div class="My_integral">
			我的积分：<div class="coins"></div><span>{{User.integral}}</span>
			<div class="tit">
				<p @click="G_Show = true">商城规则</p>
				<p @click="$router.push('/MallRecord')">兑换记录</p>
			</div>
		</div>
		<div class="Mall_nav">
			<div class="Int_box">
				<div class="Ipt_item">
					<input type="number" v-model="min_txt" @change="Shop" placeholder="最低价">
					<img src="~@/assets/img/coins-integral.svg" alt="">
				</div>
				<p>-</p>
				<div class="Ipt_item">
					<input type="number" v-model="max_txt" @change="Shop" placeholder="最高价">
					<img src="~@/assets/img/coins-integral.svg" alt="">
				</div>
				<van-button type="info" size="small" @click="PostSort">价格
					<img :class="{'down': sortTxt=='价格倒序'}" src="~@/assets/img/shop-sort-down.png" alt="">
				</van-button>
			</div>
		</div>

		<div class="Mall_list">
			<div class="Mall_item" :style="{'background-image': 'url('+'https://wanskins.oss-cn-beijing.aliyuncs.com/images/73cdd66805bfa9be08bda9b02a2c8d2a.png'+')'}" v-for="(item,index) in ShopArr" :key="index" @click="ChaseItem(item)">
				<div class="Mall_item_top">
					<p><img src="@/assets/img/coins-integral.svg" alt=""><i>{{item.integral}}</i></p>
					<span>{{item.dura_alias}}</span>
				</div>
				<div class="Mall_item_pic" :class="['lv'+item.lv]">
					<img :src="item.cover" alt="">
				</div>
				<div class="Mall_item_data">
					<p>{{item.name}}</p>
					<span><img src="@/assets/images/public/Gold.png" alt="">{{item.bean}}</span>
				</div>
			</div>
		</div>

		<el-pagination background layout="prev, pager, next" :total="total" :page-size="page_size" :key="total" @current-change="handleCurrentChange" :pager-count='5' prev-text="上一页" next-text="下一页" small>
		</el-pagination>

		<!-- 购买遮罩层 -->
		<van-overlay :show="ChaseShow" @click="CloseChaseShow">
			<div class="wrapper" @click.stop>
				<div class="LogOut_box Chase_box">
					<div class="close" style="filter: invert(1);" @click="CloseChaseShow"><img src="@/assets/images/public/close.png" alt=""></div>
					<div class="buy-title">确认兑换</div>
					<div class="tit">{{ItemData.name}}</div>
					<div style="color:#fff;text-align: center;">
						{{ItemData.dura_alias}}
					</div>
					<div class="Chase_pic" :class="['lv'+ItemData.lv]">
						<img :src="ItemData.cover" alt="">
					</div>
					<p class="Price"><img src="~@/assets/img/coins-integral.svg" alt="">{{(ItemData.integral * Chase_num).toFixed(2)}}</p>
					<van-stepper v-model="Chase_num" integer step="1" input-width="80px" button-size="40px"/>

					<van-button type="info"  @click="PostShopBuy(ItemData.id)">确定兑换</van-button>
				</div>
			</div>
		</van-overlay>
		<!-- 规则遮罩层 -->
		<van-overlay :show="G_Show">
			<div class="wrapper">
				<div class="rule-popup-con">
					<div class="rule-popup-header">
						商城规则
						<div class="close" @click="G_Show = false"></div>
					</div>
					<div class="rule-popup-main">
						<div class="rule-popup-content" v-html="publicConfig.desc_shop"></div>
					</div>
				</div>
			</div>
		</van-overlay>
	</div>
</template>

<script>
	import {
		GetShop,
		ShopBuy,
		PersonalInfo
	} from '@/network/api.js'
	import {
		mapState,
		mapMutations
	} from 'vuex'
	export default {
		name: 'Mall',
		data() {
			return {
				ShopArr: [], //列表
				total: 0, //列表总数
				page_size: 0, //一页条数
				sort: '0', //排序
				sortTxt: '价格正序', //排序显示
				min_num: '0', //价格区间(开始)
				max_num: '1000000', //价格区间(结束)
				min_txt: '', //价格开始(可见)
				max_txt: '', //价格结束(可见)
				page_num: '1', //页码
				ChaseShow:false,//弹框开关
				ItemData:{},//购买物品
				Chase_num:1,//购买数量
				G_Show: false
			}
		},

		created() {
			this.Shop()
		},

		methods: {
			//获取商店列表(初始)
			Shop() {
				GetShop(this.min_num, this.max_num, this.sort, this.page_num).then((res) => {
					// console.log(res.data.data)
					this.ShopArr = res.data.data.data
					this.total = res.data.data.total
					this.page_size = res.data.data.per_page
				})
			},

			//商品列表分页
			handleCurrentChange(val) {
				// console.log(val)
				this.page_num = val
				this.Shop()
			},

			//价格排序
			PostSort(){
				console.log(this.sortTxt, this.sortTxt == '价格倒序')
				if(this.sortTxt == '价格倒序'){
					this.sortTxt = '价格正序'
					this.sort = '0'
				}else{
					this.sortTxt = '价格倒序'
					this.sort = '1'
				}
				this.Shop()
			},

			//打开购买弹框
			ChaseItem(item){
				// console.log(item)
				this.ItemData = item
				this.ChaseShow = true
			},

			//购买操作
			PostShopBuy(id){
				ShopBuy(id,this.Chase_num).then((res) => {
					// console.log(res)
					this.RefreshData(res.data.message)
				})
			},

			//刷新数据
			RefreshData(message){
				this.$notify({
					type: 'success',
					message: message
				})
				this.CloseChaseShow()
				this.GetPersonalInfo()
			},

			//个人信息
			GetPersonalInfo() {
				PersonalInfo().then((res) => {
					this.PostUser(res.data.message)
				})
			},


			//关闭购买弹框
			CloseChaseShow(){
				this.ChaseShow = false
				setTimeout(() => {
					this.Chase_num = 1
					this.ItemData = {}
				},500)
			},


			...mapMutations([
				'PostUser'
			])
		},

		computed:{
			...mapState([
				'User',
				'publicConfig'
			])
		},

		watch: {
			min_txt(v){
				if(v == ''){
					this.min_num = '0'
				}else{
					this.min_num = v
				}
			},
			max_txt(v){
				if(v == ''){
					this.max_num = '1000000'
				}else{
					this.max_num = v
				}
			},

			Chase_num(v){
				if(v > 100){
					this.Chase_num = 100
					this.$notify({
						type: 'danger',
						message: '单次购买上限100件'
					})
				}
			}
		}
	}
</script>

<style lang="scss">
	@import "@/assets/css/base.scss";
	#Mall {
		width: 100%;
		padding: 10px;
		box-sizing: border-box;
		.My_integral{
			width: 100%;
			font-size: 16px;
			color: #fff;
			height: 30px;
			line-height: 30px;
			display: flex;
			align-items: center;
			.coins{
				background-image: url(~@/assets/img/coins-integral.svg);
				margin-right: 6px;
			}
			span{
				background: var(--LOGO, linear-gradient(180deg, #448EFD 0%, #44FDE7 100%));
				background-clip: text;
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
			}
			.tit{
				height: 100%;
				display: flex;
				align-items: center;
				margin-left: auto;
				gap: 20px;
				p{
					font-size: 14px;
					color: #fff;
					margin-left: 2px;
					text-decoration: underline;
					cursor: pointer;
				}
			}
		}
		.Mall_nav {
			width: 100%;
			border-radius: 4px;
			padding: 10px;
			box-sizing: border-box;
			.Int_box {
				display: flex;
				align-items: center;

				.Ipt_item {
					width: 96px;
					height: 32px;
					position: relative;

					&:last-child {
						margin-right: 10px;
					}

					img {
						position: absolute;
						left: 5px;
						top: 9px;
						z-index: 2;
						width: 15px;
						height: 15px;
					}

					input {
						position: absolute;
						left: 0;
						top: 0;
						width: 100%;
						height: 100%;
						border-radius: 4px;
						background: #1D1D28;
						padding-left: 24px;
						padding-right: 5px;
						box-sizing: border-box;
					}
				}
				p{
					margin: 0 4px;
				}
				.van-button{
					margin-left: auto;
					background: #1D1D28 !important;
					.van-button__text{
						@include Scenter;
						color: #fff;
						img{
							width: 16px;
							height: 16px;
							margin-left: 10px;
							&.down{
								transform: rotateZ(180deg);
							}
						}
					}
				}
			}
		}

		.Mall_list {
			width: 100%;
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			margin-bottom: 10px;

			.Mall_item {
				width: 48%;
				border: 1px solid rgba(18, 28, 39, 0.8);
				background-size: cover;
				padding: 5px 10px 12px;
				box-sizing: border-box;
				border-radius: 4px;
				margin-top: 14px;
				background-size: 100% 100%;
				background-color: #131426;
				.Mall_item_top {
					width: 100%;
					display: flex;
					height: 30px;
					position: relative;
					p {
						width: 50%;
						height: 100%;
						display: flex;
						justify-content: center;
						align-items: center;
						font-size: 12px;
						color: #75cd66;

						img {
							width: 18px;
							margin-right: 4px;
						}
					}

					span {
						display: block;
						font-size: 12px;
						color: #333;
						position: absolute;
						right: 0;
						top: 0;
						height: 20px;
						@include HScenter;
						padding: 0 4px;
						background: #FFB800;
						&::before{
							content: '';
							position: absolute;
							border-color: #FFB800 #FFB800 transparent transparent;
							border-style: solid;
							border-width: 10px 6px;
							left: -12px;
						}
					}

					i{
						background: var(--LOGO, linear-gradient(180deg, #448EFD 0%, #44FDE7 100%));
						background-clip: text;
						-webkit-background-clip: text;
						-webkit-text-fill-color: transparent;
						font-style: normal;
					}
				}

				.Mall_item_pic {
					width: 80%;
					height: 100px;
					margin: 0 auto;
					display: flex;
					justify-content: center;
					align-items: center;
					background-size: 70% !important;
					background-position: center !important;
					background-repeat: no-repeat !important;
					&.lv1{
						background-image: url(~@/assets/img/product/roll-lv-bg-1.png);
					}
					&.lv2{
						background-image: url(~@/assets/img/product/roll-lv-bg-2.png);
					}
					&.lv3{
						background-image: url(~@/assets/img/product/roll-lv-bg-3.png);
					}
					&.lv4{
						background-image: url(~@/assets/img/product/roll-lv-bg-4.png);
					}
					&.lv5{
						background-image: url(~@/assets/img/product/roll-lv-bg-5.png);
					}
					img {
						max-width: 100%;
						max-height: 100%;
					}
				}

				.Mall_item_data {
					width: 100%;
					color: #fff;
					text-align: center;

					p {
						font-size: 14px;
						line-height: 20px;
						margin-bottom: 5px;
					}

					span {
						font-size: 12px;
						color: #fff;
						img{
							width: 14px;
							vertical-align: middle;
							margin-bottom: 2px;
						}
					}
				}
			}

		}
		.Chase_box{
			text-align: center;
			width: 350px;
			height: 428px;
			background-image: url(~@/assets/img/shop-buy-bg.png);
			background-size: 100% 100%;
			.buy-title{
				background: url(~@/assets/img/shop-buy-title-bg.png) no-repeat center center;
				background-size: 100% 100%;
				width: 261px;
				height: 32px;
				@include HScenter;
				margin: 0 auto 12px;
			}
			.Chase_pic{
				width: 184px;
				height: 130px;
				margin: 10px auto;
				display: flex;
				justify-content: center;
				align-items: center;
				background-size: 70% !important;
				background-position: center !important;
				background-repeat: no-repeat !important;
				&.lv1{
					background-image: url(~@/assets/img/product/roll-lv-bg-1.png);
				}
				&.lv2{
					background-image: url(~@/assets/img/product/roll-lv-bg-2.png);
				}
				&.lv3{
					background-image: url(~@/assets/img/product/roll-lv-bg-3.png);
				}
				&.lv4{
					background-image: url(~@/assets/img/product/roll-lv-bg-4.png);
				}
				&.lv5{
					background-image: url(~@/assets/img/product/roll-lv-bg-5.png);
				}
				img{
					max-width: 100%;
					max-height: 100%;
					vertical-align: middle;
				}
			}
			.Price{
				font-size: 18px;
				margin-bottom: 15px;
				@include HScenter;
				img{
					width: 20px;
					height: 20px;
					margin-right: 6px;
				}
			}
			.van-button{
				margin-top: 10px;
				font-size: 16px;
				background: url(~@/assets/img/agree-btn-bg.png) !important;
				background-size: 100% 100% !important;
				color: #fff !important;
				width: 258px;
				height: 52px;
			}
			.van-stepper__input{
				background: #2F4E7C;
				color: #fff;
				margin: 0 10px;
			}
			.van-stepper__minus{
				width: 50px !important;
				background: url(~@/assets/img/step-min.png);
				background-size: 100% 100%;
				border-radius: 0;
				color: #fff !important;
			}
			.van-stepper__plus{
				width: 50px !important;
				background: url(~@/assets/img/step-max.png);
				background-size: 100% 100%;
				border-radius: 0;
				color: #fff !important;
			}
		}

	}
</style>
